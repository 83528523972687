<template>
  <v-app-bar app height="124" class="align-start elevation-1">
    <v-col cols="12" class="py-0">
      <v-row dense class="align-center">
        <v-col cols="auto" class="pa-0 flex-grow-1">
          <v-row dense class="align-center flex-nowrap" style="height: 60px;">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            </v-col>
            <v-col cols="auto" v-if="filterIsActive" @click="resetFilters">
              <v-btn outlined color="red lighten-2"
                ><v-icon>mdi-filter-off</v-icon><span class="ml-1 d-none d-lg-flex">Filter aktiv!</span></v-btn
              >
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" v-if="$route.meta.request === 'appointments/requests'">
              <v-btn
                color="red lighten-2 grey--text text--lighten-4"
                :disabled="datasetIsSelected.length == 0"
                @click="deleteDatasets()"
              >
                <v-icon>mdi-delete</v-icon>
                <span class="ml-1 d-none d-lg-flex">Verwerfen</span>
              </v-btn>
            </v-col>
            <v-col class="d-none" cols="auto" v-if="$route.meta.request === 'appointments/table'">
              <v-btn
                color="red lighten-2 grey--text text--lighten-4"
                :disabled="datasetIsSelected.length === 0"
                @click="deleteDatasets()"
              >
                <v-icon>mdi-delete</v-icon>
                <span class="ml-1 d-none d-lg-flex">Absagen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="$route.meta.request === 'appointments/table'">
              <v-btn color="green lighten-2 grey--text text--lighten-4" :to="linkCreateDataset">
                <v-icon>mdi-plus</v-icon>
                <span class="ml-1 d-none d-lg-flex">Neu</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="align-center flex-nowrap" style="height: 64px;">
            <v-col cols="4" xl="2" lg="2" md="4" sm="4">
              <v-text-field
                dense
                hide-details
                outlined
                v-model="filter.search"
                prepend-icon="mdi-magnify"
                clearable
                placeholder="Suche"
                @input="filterChange()"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              class="d-none d-lg-flex flex-shrink-1"
              cols="2"
              v-if="$route.meta.request === 'appointments/table' || $route.meta.request === 'calendar'"
            >
              <v-select
                @input="filterChange()"
                v-model="filter.month"
                dense
                hide-details
                :items="availableMonths"
                return-object
                outlined
                item-value="value"
                item-text="text"
              ></v-select>
            </v-col>
            <v-col
              class="d-none d-lg-flex flex-shrink-1"
              cols="2"
              v-if="$route.meta.request === 'appointments/table' || $route.meta.request === 'calendar'"
            >
              <v-select
                @input="filterChange()"
                v-model="filter.year"
                dense
                hide-details
                :items="availableYears"
                return-object
                outlined
                item-value="value"
                item-text="text"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto" class="d-none d-lg-flex" v-if="$route.meta.request === 'appointments/requests'">
              <v-btn-toggle v-model="filter.category" tile mandatory color="primary" outlined @change="filterChange()">
                <v-btn class="mr-3">
                  Alle
                </v-btn>
                <v-btn style="border-left: 1px solid rgba(0,0,0,0.1)">
                  Fragen
                </v-btn>
                <v-btn>
                  Allgemein
                </v-btn>
                <v-btn>
                  Braut
                </v-btn>
                <v-btn>
                  Herren
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="auto" class="d-none d-lg-flex" v-if="$route.meta.request === 'appointments/table'">
              <v-btn-toggle v-model="filter.category" tile mandatory color="primary" outlined @change="filterChange()">
                <v-btn class="mr-1">
                  Alle
                </v-btn>
                <v-btn style="border-left: 1px solid rgba(0,0,0,0.1)">
                  Braut
                </v-btn>
                <v-btn>
                  Fest
                </v-btn>
                <v-btn>
                  Herren
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-menu offset-y :nudge-left="266" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-card width="330">
                  <v-card-title><span>Spalten ein/ausblenden:</span> </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="text--primary">
                    <v-row dense align="center" justify="center">
                      <v-col cols="6" v-for="item in headersToToggle" :key="item.Spalte">
                        <v-checkbox
                          hide-details
                          dense
                          v-model="item.nicht_versteckt"
                          @change="reactToChange($event, item.Spalte)"
                        >
                          <template v-slot:label
                            ><span class="text--primary">
                              {{ item.Spalte }}
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <v-menu offset-y :nudge-left="266" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-card width="330">
                  <v-card-title><span>Weitere Optionen:</span> </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="text--primary">
                    <v-row dense align="center">
                      <v-col cols="6" class="d-flex d-lg-none">
                        <v-select
                          @input="filterChange()"
                          v-model="filter.month"
                          dense
                          hide-details
                          :items="availableMonths"
                          return-object
                          outlined
                          item-value="value"
                          item-text="text"
                        ></v-select>
                      </v-col>
                      <v-col cols="6" class="d-flex d-lg-none">
                        <v-select
                          @input="filterChange()"
                          v-model="filter.year"
                          dense
                          hide-details
                          outlined
                          :items="availableYears"
                          return-object
                          item-value="value"
                          item-text="text"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" class="d-flex d-lg-none">
                        <v-btn-toggle
                          v-model="filter.category"
                          tile
                          mandatory
                          color="primary"
                          outlined
                          @change="filterChange()"
                        >
                          <v-btn class="mr-3">
                            Alle
                          </v-btn>
                          <v-btn style="border-left: 1px solid rgba(0,0,0,0.1)">
                            Fest
                          </v-btn>
                          <v-btn>
                            Braut
                          </v-btn>
                          <v-btn>
                            Herren
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox dense hide-details v-model="filter.placeholder" @change="filterChange()">
                          <template v-slot:label>
                            <span v-if="$route.meta.request === 'appointments/requests'" class="text--primary"
                              >verworfene anzeigen</span
                            >
                            <span v-if="$route.meta.request === 'appointments/table'" class="text--primary"
                              >vergangene anzeigen</span
                            >
                          </template>
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" class="d-none">
                        <v-checkbox dense hide-details v-model="filter.placeholder2" @change="filterChange()">
                          <template v-slot:label>
                            <span v-if="$route.meta.request === 'appointments/table'" class="text--primary"
                              >abgesagte anzeigen</span
                            >
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-app-bar>
</template>

<script>
export default {
  mounted() {
    this.initialize();
  },

  props: {
    datasetIsSelected: { type: Array, default: [] },
    searchTableIsActive: { type: Boolean, default: false },
  },

  data: () => ({
    availableYears: [
      {
        text: "Jahr",
        value: null,
      },
      {
        text: "2020",
        value: 2020,
      },
      {
        text: "2021",
        value: 2021,
      },
      {
        text: "2022",
        value: 2022,
      },
      {
        text: "2023",
        value: 2023,
      },
      {
        text: "2024",
        value: 2024,
      },
      {
        text: "2025",
        value: 2025,
      },
    ],
    availableMonths: [
      {
        text: "Monat",
        value: null,
      },
      {
        text: "Jan",
        value: 0,
      },
      {
        text: "Feb",
        value: 1,
      },
      {
        text: "Mär",
        value: 2,
      },
      {
        text: "Apr",
        value: 3,
      },
      {
        text: "Mai",
        value: 4,
      },
      {
        text: "Jun",
        value: 5,
      },
      {
        text: "Jul",
        value: 6,
      },
      {
        text: "Aug",
        value: 7,
      },
      {
        text: "Sep",
        value: 8,
      },
      {
        text: "Okt",
        value: 9,
      },
      {
        text: "Nov",
        value: 10,
      },
      {
        text: "Dez",
        value: 11,
      },
    ],
    filter: {
      month: { text: "Monat", value: null },
      year: { text: "Jahr", value: null },
      search: "",
      category: 0,
      placeholder: null,
      palceholder2: null,
    },
    headersToToggle: [],
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      return !!(user === "Aline" || user === "andre");
    },

    filterIsActive() {
      return !!(
        this.filter.search ||
        this.searchTableIsActive ||
        this.filter.category ||
        this.filter.year.value ||
        this.filter.month.value
      );
    },

    linkCreateDataset() {
      if (this.$route.meta.request) {
        return `/wws/${this.$route.meta.request}/dataset/create`;
      }
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/availabletableheaders`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.initializefilter();
    },

    async reactToChange(value, item) {
      if (value === true) {
        await fetch(`/api/${this.$route.meta.request}/tableheader/delete/${item}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.$emit("initHeaders", true);
      } else
        await fetch(`/api/${this.$route.meta.request}/tableheader/insert/${item}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        });
      this.$emit("initHeaders", true);
    },

    initializefilter() {
      if (this.$route.meta.request === "appointments/requests") {
        const search = localStorage.getItem("appointmentsRequestsFilterSearch");

        if (search === "null" || search === null) {
          this.filter.search = "";
        } else this.filter.search = localStorage.getItem("appointmentsRequestsFilterSearch");

        const placeholder = localStorage.getItem("appointmentsRequestsFilterPlaceholder");

        if (placeholder === "false") {
          this.filter.placeholder = false;
        } else if (placeholder === "true") {
          this.filter.placeholder = true;
        } else this.filter.placeholder = false;
      } else if (this.$route.meta.request === "appointments/table") {
        const search = localStorage.getItem("appointmentsFilterSearch");

        if (search === "null" || search === null) {
          this.filter.search = "";
        } else this.filter.search = localStorage.getItem("appointmentsFilterSearch");

        const placeholder = localStorage.getItem("appointmentsFilterPlaceholder");

        if (placeholder === "false") {
          this.filter.placeholder = false;
        } else if (placeholder === "true") {
          this.filter.placeholder = true;
        } else this.filter.placeholder = false;

        const placeholder2 = localStorage.getItem("appointmentsFilterPlaceholder2");

        if (placeholder2 === "false") {
          this.filter.placeholder2 = false;
        } else if (placeholder2 === "true") {
          this.filter.placeholder2 = true;
        } else this.filter.placeholder2 = false;
      }

      this.filterChange();
    },

    filterChange() {
      const newfilter = {
        month: this.filter.month,
        year: this.filter.year,
        search: this.filter.search,
        category: this.filter.category,
        placeholder: this.filter.placeholder,
        placeholder2: this.filter.placeholder2,
      };

      this.$emit("filterChanged", newfilter);

      if (this.$route.meta.request === "appointments/requests") {
        localStorage.setItem("appointmentsRequestsFilterSearch", this.filter.search);
        localStorage.setItem("appointmentsRequestsFilterPlaceholder", this.filter.placeholder);
      } else if (this.$route.meta.request === "appointments/table") {
        localStorage.setItem("appointmentsFilterSearch", this.filter.search);
        localStorage.setItem("appointmentsFilterPlaceholder", this.filter.placeholder);
        localStorage.setItem("appointmentsFilterPlaceholder2", this.filter.placeholder2);
      } else if (this.$route.meta.request === "calendar") {
        localStorage.setItem("calendarFilterSearch", this.filter.search);
        localStorage.setItem("calendarFilterPlaceholder", this.filter.placeholder);
      }

      this.$emit("filterResetted", true);
    },

    resetFilters() {
      this.filter.search = "";
      this.filter.category = 0;
      this.filter.month = { text: "Monat", value: null };
      this.filter.year = { text: "Jahr", value: null };
      this.$emit("resetFilters", true);
      this.filterChange();
    },

    deleteDatasets() {
      this.$emit("deleteSelectedDatasets", true);
    },

    generatePDF() {
      this.$emit("createPDF", true);
    },
  },
};
</script>

<template>
  <v-layout>
    <AppointmentsAppBar
      :datasetIsSelected="selected"
      :searchTableIsActive="filterActive"
      @initHeaders="initialize"
      @filterChanged="setFilter"
      @resetFilters="resetFilter"
      @deleteSelectedDatasets="openCancelDatasets"
      @createPDF="downloadPDF"
    />

    <v-data-table
      v-model="selected"
      calculate-widths
      class="pl-1"
      height="calc(100vh - 124px - 69px)"
      fixed-header
      hide-default-footer
      :items="filteredByCreator"
      item-key="Termin_ID"
      :items-per-page="100"
      :item-class="rowColor"
      :headers="datasetHeaders"
      :page.sync="page"
      :search="filter.search"
      @page-count="pageCount = $event"
      @dblclick:row="showDataset"
      :single-select="false"
      :show-select="true"
      mobile-breakpoint="300"
      style="width: 100%"
    >
      <template #[`header.Artikelnummer`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span
                v-if="!tableSearch.searchArticlenumber"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelnummer
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span
                v-if="tableSearch.searchArticlenumber"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelnummer
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlenumber"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>
      <template #[`item.Artikelnummer`]="{ item }">
        <span class="font-weight-bold">{{ item.Artikelnummer }}</span>
      </template>

      <template #[`header.Artikelname`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span
                v-if="!tableSearch.searchArticlename"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelname
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span
                v-if="tableSearch.searchArticlename"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelname
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlename"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Farben`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span
                v-if="!tableSearch.searchArticlecolor"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Farben
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span
                v-if="tableSearch.searchArticlecolor"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Farben
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlecolor"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Größe`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span
                v-if="!tableSearch.searchArticlesize"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Größe
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span
                v-if="tableSearch.searchArticlesize"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Größe
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlesize"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Kundenname`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span
                v-if="!tableSearch.searchClientname"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kundenname
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span
                v-if="tableSearch.searchClientname"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Kundenname
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchClientname"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`item.Kundenname`]="{ item }">
        <span class="font-weight-bold">{{ item.Kundenname }}</span>
      </template>

      <template #[`item.Datum`]="{ item }">
        <span class="font-weight-bold" v-if="item.Datum">{{
          new Date(item.Datum).toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
        }}</span>
      </template>

      <template #[`header.Eintrag_von`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span
                v-if="!tableSearch.searchCreator"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Eintrag von
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span
                v-if="tableSearch.searchCreator"
                class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Eintrag von
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchCreator"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #no-data>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px)">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <template #no-results>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px)">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-footer app inset height="69" elevation="1">
      <v-col cols="2"></v-col>
      <v-col cols="8" class="pa-0">
        <v-row dense align="center" justify="start" class="px-1">
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="2" class="pa-0">
        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined icon v-bind="attrs" v-on="on">
              <v-icon> mdi-information-variant</v-icon>
            </v-btn>
          </template>
          <v-card min-width="400">
            <v-card-title>
              2022
            </v-card-title>
            <v-card-text class="text--primary">
              <v-row dense align="center" justify="center">
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Braut:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2022.Braut >= 120
                            ? 'green--text'
                            : amounts2022.Braut >= 75
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2022.Braut }}</span
                      >
                      <span class="font-weight-bold">/120</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Fest:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2022.Fest >= 200
                            ? 'green--text'
                            : amounts2022.Fest >= 125
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2022.Fest }}</span
                      >
                      <span class="font-weight-bold">/200</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Herren:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2022.Herren >= 90
                            ? 'green--text'
                            : amounts2022.Herren >= 60
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2022.Herren }}</span
                      >
                      <span class="font-weight-bold">/90</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title>
              2023
            </v-card-title>
            <v-card-text class="text--primary">
              <v-row dense align="center" justify="center">
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Braut:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2023.Braut >= 130
                            ? 'green--text'
                            : amounts2023.Braut >= 100
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2023.Braut }}</span
                      >
                      <span class="font-weight-bold">/130</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Fest:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2023.Fest >= 200
                            ? 'green--text'
                            : amounts2023.Fest >= 125
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2023.Fest }}</span
                      >
                      <span class="font-weight-bold">/200</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Herren:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2023.Herren >= 100
                            ? 'green--text'
                            : amounts2023.Herren >= 70
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2023.Herren }}</span
                      >
                      <span class="font-weight-bold">/100</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title>
              2024
            </v-card-title>
            <v-card-text class="text--primary">
              <v-row dense align="center" justify="center">
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Braut:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2024.Braut >= 130
                            ? 'green--text'
                            : amounts2024.Braut >= 100
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2024.Braut }}</span
                      >
                      <span class="font-weight-bold">/130</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Fest:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2024.Fest >= 200
                            ? 'green--text'
                            : amounts2024.Fest >= 125
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2024.Fest }}</span
                      >
                      <span class="font-weight-bold">/200</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Herren:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2024.Herren >= 100
                            ? 'green--text'
                            : amounts2024.Herren >= 70
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2024.Herren }}</span
                      >
                      <span class="font-weight-bold">/100</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title>
              2025
            </v-card-title>
            <v-card-text class="text--primary">
              <v-row dense align="center" justify="center">
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Braut:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2025.Braut >= 130
                            ? 'green--text'
                            : amounts2025.Braut >= 100
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2025.Braut }}</span
                      >
                      <span class="font-weight-bold">/130</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Fest:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2025.Fest >= 200
                            ? 'green--text'
                            : amounts2025.Fest >= 125
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2025.Fest }}</span
                      >
                      <span class="font-weight-bold">/200</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="4" class="text-center">
                  <div class="d-flex flex-column">
                    <span>Herren:</span>
                    <div class="text-no-wrap">
                      <span
                        class="font-weight-bold"
                        :class="
                          amounts2025.Herren >= 100
                            ? 'green--text'
                            : amounts2025.Herren >= 70
                            ? 'orange--text'
                            : 'red--text'
                        "
                        >{{ amounts2025.Herren }}</span
                      >
                      <span class="font-weight-bold">/100</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-col>
    </v-footer>

    <CancelDatasetsDialog
      ref="CancelDatasets"
      @confirmCancelDatasets="cancelDatasets"
      @abortCancelDatasets="abortCancelDatasets"
    />
  </v-layout>
</template>

<script>
import jsPDF from "jspdf";
import AppointmentsAppBar from "../../components/appbars/AppointmentsAppBar.vue";
import CancelDatasetsDialog from "../../components/dialogs/CancelDatasets.vue";

export default {
  components: {
    AppointmentsAppBar,
    CancelDatasetsDialog,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    filter: {
      month: {},
      year: {},
      search: "",
      placeholder: null,
      placeholder2: null,
      category: 0,
    },
    tableSearch: {
      searchArticlenumber: null,
      searchArticlename: null,
      searchArticlecolor: null,
      searchArticlesize: null,
      searchClientname: null,
      searchCreator: null,
    },
    datasets: [],
    datasetHeaders: [],
    ausgeblendeteSpalten: [],
    hiddenColumns: [],
    page: 1,
    pageCount: 0,
    selected: [],
    CancelDatasets: false,
    amounts2022: {},
    amounts2023: {},
    amounts2024: {},
    amounts2025: {},
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      return user === "Aline" || user === "andre";
    },

    checkIfCanceled(item) {
      return item.ist_abgesagt === 0;
    },

    filteredByPlaceholder() {
      if (this.datasets && !this.filter.placeholder) {
        return this.datasets.filter((item) => item.Datum >= new Date().toISOString("de-DE"));
      } else if (this.datasets && this.filter.placeholder) {
        return this.datasets;
      }
    },

    filteredByPlaceholder2() {
      if (this.filteredByPlaceholder && !this.filter.placeholder2) {
        return this.filteredByPlaceholder.filter((item) => item.ist_abgesagt === 0);
      } else if (this.filteredByPlaceholder && this.filter.placeholder2) {
        return this.filteredByPlaceholder;
      }
    },

    filteredByCategory() {
      if (this.filteredByPlaceholder2 && this.filter.category >= 1) {
        return this.filteredByPlaceholder2.filter((item) => item.Abteilung_ID === this.filter.category);
      } else if (this.filteredByPlaceholder2 && this.filter.category === 0) {
        return this.filteredByPlaceholder2;
      }
    },

    filteredByMonth() {
      if (this.filteredByCategory && this.filter.month.value) {
        return this.filteredByCategory.filter((item) => new Date(item.Datum).getMonth() === this.filter.month.value);
      } else return this.filteredByCategory;
    },

    filteredByYear() {
      if (this.filteredByMonth && this.filter.year.value) {
        return this.filteredByMonth.filter((item) => new Date(item.Datum).getFullYear() === this.filter.year.value);
      } else return this.filteredByMonth;
    },

    filteredByArticlename() {
      if (this.tableSearch.searchArticlename) {
        return this.filteredByYear.filter(
          (item) => item.Artikelname != null && item.Artikelname.includes(this.tableSearch.searchArticlename)
        );
      } else return this.filteredByYear;
    },

    filteredByArticlenumber() {
      if (this.tableSearch.searchArticlenumber) {
        return this.filteredByArticlename.filter(
          (item) => item.Artikelnummer != null && item.Artikelnummer.includes(this.tableSearch.searchArticlenumber)
        );
      } else return this.filteredByArticlename;
    },

    filteredByArticlecolor() {
      if (this.tableSearch.searchArticlecolor) {
        return this.filteredByArticlenumber.filter(
          (item) => item.Farben != null && item.Farben.includes(this.tableSearch.searchArticlecolor)
        );
      } else return this.filteredByArticlenumber;
    },

    filteredByArticlesize() {
      if (this.tableSearch.searchArticlesize) {
        return this.filteredByArticlecolor.filter(
          (item) => item.Größe != null && item.Größe.includes(this.tableSearch.searchArticlesize)
        );
      } else return this.filteredByArticlecolor;
    },

    filteredByClientname() {
      if (this.tableSearch.searchClientname) {
        return this.filteredByArticlesize.filter(
          (item) => item.Kundenname != null && item.Kundenname.includes(this.tableSearch.searchClientname)
        );
      } else return this.filteredByArticlesize;
    },

    filteredByCreator() {
      if (this.tableSearch.searchCreator) {
        return this.filteredByClientname.filter(
          (item) => item.Eintrag_von != null && item.Eintrag_von.includes(this.tableSearch.searchCreator)
        );
      } else return this.filteredByClientname;
    },

    filterActive() {
      return !!(
        this.tableSearch.searchArticlenumber ||
        this.tableSearch.searchArticlename ||
        this.tableSearch.searchArticlecolor ||
        this.tableSearch.searchArticlesize ||
        this.tableSearch.searchClientname ||
        this.tableSearch.searchCreator
      );
    },
  },

  methods: {
    setFilter(newfilter) {
      this.filter = { ...newfilter };
    },

    resetFilter() {
      this.tableSearch.searchArticlenumber = null;
      this.tableSearch.searchArticlename = null;
      this.tableSearch.searchArticlecolor = null;
      this.tableSearch.searchArticlesize = null;
      this.tableSearch.searchClientname = null;
      this.tableSearch.searchCreator = null;
    },

    resetSelected() {
      this.selected = [];
    },

    showDataset(event, { item }) {
      this.$router.push({
        path: `/wws/${this.$route.meta.request}/dataset/show/${item.Termin_ID}`,
      });
    },

    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/tableheaders`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.hiddenColumns = this.ausgeblendeteSpalten.map((item) => item.Spalte);

      this.resetFilter();

      this.initializeContent();
    },

    async initializeContent() {
      const response = await fetch(`/api/${this.$route.meta.request}/datasets`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: true,
            align: "center",
            divider: true,
            class: "text--primary text-uppercase",
          };
        });
    },

    rowColor(item) {
      if (item.Terminkategorie_ID === 1 && item.ist_abgesagt === 0) {
        return "Leih";
      } else if (item.Terminkategorie_ID === 2 && item.ist_abgesagt === 0) {
        return "Kauf";
      } else if (item.Terminkategorie_ID === 3 && item.ist_abgesagt === 0) {
        return "Bestand";
      } else return "";
    },

    //DIALOG ZUR BESTÄTIGUNG UM EINEN TERMIN ZU LÖSCHEN
    openCancelDatasets() {
      this.$refs.CancelDatasets.open();
    },

    //TERMIN LÖSCHEN WENN LÖSCHUNG BESTÄTIGT
    cancelDatasets() {
      const multipleDatasetCancel = this.selected;
      const amountCancelDatasets = multipleDatasetCancel.length;
      const request = this.$route.meta.request;

      multipleDatasetCancel.forEach(async function(dataset) {
        const datasetToCancel = {};
        const datasetAddCancelNote = {};
        const datasetArticleToAvailable = {};

        if (Object.keys(datasetToCancel).length === 0) {
          Object.assign(datasetToCancel, {
            Termin_ID: dataset.Termin_ID,
            ist_abgesagt: 1,
            Preis: null,
          });

          await fetch(`/api/${request}/dataset/cancelappointment`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetToCancel),
          });
        }

        if (Object.keys(datasetAddCancelNote).length === 0) {
          Object.assign(datasetAddCancelNote, {
            Termin_ID: dataset.Termin_ID,
            Notiz: "Termin wurde abgesagt",
          });

          await fetch(`/api/${request}/note/insertnote`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetAddCancelNote),
          });
        }

        if (Object.keys(datasetArticleToAvailable).length === 0) {
          Object.assign(datasetArticleToAvailable, {
            Artikel_ID: dataset.Artikel_ID,
            Status_ID: 1,
          });

          await fetch(`/api/${request}/article/articlestatuschange`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(datasetArticleToAvailable),
          });
        }
      });

      this.$root.actionSnackbar.show({
        amount: String(amountCancelDatasets),
        dataset: "Termin(e)",
        action: "abgesagt",
      });
      this.CancelDatasets = false;
      this.selected = [];
      this.initialize();
    },

    //LÖSCHEN ABBRECHEN
    abortCancelDatasets() {
      this.CancelDatasets = false;
    },

    downloadPDF() {
      var initialdata = this.filteredByProductline;

      initialdata.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          if (o[key] === null || o[key] === "") {
            o[key] = " ";
          }
        });
      });

      var data = initialdata;

      var initialheaders = this.datasetHeaders.map((item) => item.text);
      var headers = initialheaders;

      var dateandtime = new Date();
      var currentdate = dateandtime.toLocaleDateString("de-DE");

      if (headers.length > 4) {
        var doc = new jsPDF({
          orientation: "landscape",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      } else if (headers.length <= 4) {
        var doc = new jsPDF({
          orientation: "portrait",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      }

      this.initialize();
    },
  },
};
</script>

<style>
.Leih {
  background: #53ff5c !important;
}

.Kauf {
  background: #fcff51 !important;
}

.Bestand {
  background: #d52bff !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  cursor: pointer;
  background: #ddddddc5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.Leih:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #53ff5cc5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.Kauf:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fcff51c5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.Bestand:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #d52bffc5 !important;
}
</style>

<template>
  <v-dialog
    v-model="CancelDatasets"
    persistent
    width="475px"
    overlay-opacity="0.75"
  >
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title>Termin absagen</v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text class="text--primary py-5 px-3">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="auto"
              >Möchtest du den Termin / die Termine wirklich absagen? Die verliehenen / verkauften Artikel werden dadurch wieder verfügbar!
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="red lighten-1"
                @click="abortCancelDataset()"
              >
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="green lighten-1" @click="confirmCancelDataset()">
                <v-icon left class="grey--text text--lighten-4"
                  >mdi-check
                </v-icon>
                <span class="grey--text text--lighten-4"
                  >Absagen</span
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    CancelDatasets: false,
  }),

  methods: {
    open() {
      this.CancelDatasets = true;
    },

    confirmCancelDataset() {
      this.CancelDatasets = false;
      this.$emit("confirmCancelDatasets", true);
    },

    abortCancelDataset() {
      this.CancelDatasets = false;
      this.$emit("abortCancelDatasets", true);
    },
  },
};
</script>
